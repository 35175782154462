<template>
  <SizeSelectionModal
    :product-sizes="product.sizes || []"
    :selected-size="selectedSize"
    :archetype="archetype"
    :is-sidebar="isSidebar"
    @size="selectedSize = $event"
    @submit="addToCart"
  />
</template>

<script>
import UIkit from 'uikit'
import { mapActions, mapGetters } from 'vuex'

import SizeSelectionModal from '@/web/vue/shared/components/SizeSelectionModal.vue'
import { getArchetypeFromType } from '@/web/js/helpers'

export default {
  name: 'ProductChartCartModal',
  components: { SizeSelectionModal },
  props: {
    isSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedSize: undefined,
    }
  },
  computed: {
    ...mapGetters({ product: 'basket/currentProduct' }),
    archetype() {
      return getArchetypeFromType(this.product?.typeslug || '')
    },
  },
  watch: {
    product() {
      this.selectedSize = undefined
    },
  },
  methods: {
    ...mapActions('basket', ['addItem']),
    addToCart() {
      this.addItem({ ...this.product, quantity: 1, size: this.selectedSize })
      UIkit.modal('#sizeSelectionModal')?.hide()
    },
  },
}
</script>
